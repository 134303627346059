import logo from './logo.svg';
import './App.css';
import React from 'react';
import { useEffect, useState } from 'react';
import Navbar from './Navbar'
import Footer from './Footer';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import MainFeaturedPost from './MainFeaturedPost';
import Reviews from './Reviews';

import {


  Row,

} from 'bootstrap-4-react';

import Text from './Text';
import Embed from './Embed';
import Img from './Image'

import Booking from './Booking';
const style = {
  minWidth: '100vw',
  minHeight: '100vh',
  container: {
    maxWidth: '960px'
  },
  lhCondensed: {
    lineHeight: '1.25'
  }
}

const hideFeature = ["booking", "checkout"]
const override = {
  "items": [
    {
      "opacity": "0.7",
      "disabled": false,
      "type": "post",
      "title": "Relax in Luxury",
      "description": "Looking for a luxurious and relaxing holiday? Look no further than Eden Gardens. Our beautiful holiday homes...",
      "image": "/images/apple.jpg",
      "imageText": "The Ultimate Escape",
      "linkText": "Continue reading…",
      "link": "https://www.booking.com/hotel/gb/eden-garden-luxury-lodges.en-gb.html"
    },
    {
      "disabled": false,
      "opacity": "1",
      "type": "post",
      "title": "Book Now",
      "description": "Book Your Break Now.",
      "image": "/images/book.jpg",
      "imageText": "Book Now",
      "linkText": "Continue reading…",
      "link": "https://www.booking.com/hotel/gb/eden-garden-luxury-lodges.en-gb.html"
    },
    {
      "disabled": false,
      "opacity": "1",
      "type": "post",
      "title": "Book Now",
      "description": "Schools out! Book Your Healf Term Break.",
      "image": "/images/schoolhols.jpg",
      "imageText": "Book Now",
      "linkText": "Continue reading…",
      "link": "https://www.booking.com/hotel/gb/eden-garden-luxury-lodges.en-gb.html"
    },
    {
      "disabled": false,
      "opacity": "0.9",
      "type": "post",
      "title": "Book Now",
      "description": "Black Friday Deals",
      "image": "/images/blackfriday.jpg",
      "imageText": "Book Now",
      "linkText": "Continue reading…",
      "link": "https://www.booking.com/hotel/gb/eden-garden-luxury-lodges.en-gb.html"
    },
    {
      "type": "embed",
      "link": ""

    },
    {
      "type": "text",
      "size": "7",
      "description": "Follow the signs after leaving the B4312 Llansteffan Road to find us:"
    },
    {
      "type": "singleimage",
      "image": "/images/direct.png",
      "description": "Fdddollow the signs after leaving the B4312 Llansteffan Road to find us:",
      "linkText": "Book Now",
      "link": "/book"
    }
  ]
}



const override2 = {
  "items": [
    {
      "type": "text",
      "items": [
        {
          "type": "text",
          "size": "7",

          "image": "/images/beach.jpg",
          "size": "7",
          "description": "Set on the outskirts of the quiet seaside village of Llansteffan, Eden Garden Luxury Lodges offer our guests an excellent opportunity to base themselves for a relaxing stay in a rural area or to explore the attractions and unspoilt countryside areas of Carmarthenshire and Pembrokeshire. The location provides an ideal opportunity to explore nearby activities that include the National Coastal Path, Llansteffan Beach and Llansteffan Castle."
        },
        {
          "type": "text",
          "image": "/images/llansteffan.jpg",
          "size": "7",
          "description": "The location of the lodges historically formed part of the Mansion House Estate and was originally a vegetable and herb walled garden supplying produce to the Estate. In 1972 the land was repurposed as a cooperative site consisting of 105 lodges that are all still present today. Eden Garden forms a private three lodge annex from the other 102 lodges."
        },
        {
          "type": "text",
          "image": "/images/0.jpg",
          "size": "7",
          "description": "Eden Garden was purchased by Mark and Eve in 2022 and the lodges have been fully refurbished into an open plan contemporary style. With guest comfort being a priority, the lodges are provided with a fully equipped kitchen, comfortable lounge area, flat screen smart television as well as high quality bedding to ensure a good night’s rest. Please refer to the specific lodge information page for further information."
        }
      ]
    }

  ]
}

function App() {
 // const navigate = useNavigate();

  let location = useLocation();
  const [pageData, setPageData] = useState({})
  const [menuData, setMenuData] = useState({})
  let urlParams = useParams();




  useEffect(() => {
    (async () => {
      const response = await fetch(`https://raw.githubusercontent.com/edenlets/edenconfig/main/menu.json`)

      const jsonData = await response.json().catch((err) => {
        console.log(err)
      });
      setMenuData(jsonData)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      let response
      if (!hideFeature.includes(urlParams.page)) {
        console.log(urlParams)
        response = await fetch(`https://raw.githubusercontent.com/edenlets/edenconfig/main/${urlParams.page || 'home'}.json`)

        const jsonData = await response.json().catch(() => {
       //   navigate("/home")
          window.location.reload(false);
        });
        setPageData(jsonData)
      } else {
      //  navigate("/home")
        window.location.reload(false);

      }
      //console.log(jsonData)


    })()

    document.title = urlParams.page

  }, [urlParams])



  // const dat=[
  //   {
  //     type: "navleft", items: [
  //       { type: "link", display: "Features", url: "#features" },
  //       { type: "link", display: "Pricing", url: "#pricing" },
  //       {
  //         type: "dropdown", display: "Dropdown", url: "#features", items: [
  //           { type: "dropdown_item", display: "Action", url: "#action/3.1" },
  //           { type: "dropdown_item", display: "Another action", url: "#action/3.2" },
  //           { type: "dropdown_item", display: "Something", url: "#action/3.3" },
  //           { type: "divider" },
  //           { type: "dropdown_item", display: "Seperated Link", url: "#action/3.4" },
  //         ]
  //       }]
  //   },
  //   {
  //     type: "navright", items: [
  //       { type: "link", display: "More deets", url: "#deets" },
  //       { type: "link", display: "Dank memes", url: "#memes" },
  //     ]
  //   }
  // ]

  return (
    <>


      {menuData?.items &&
        <Navbar data={menuData?.items} title="" image={menuData.logo}></Navbar>
      }

      {/* <Row mb="2"> */}
      {pageData?.items?.map((item, index) => {
        if (item.disabled === true) {

        } else {
          // console.log(index)
          switch (item.type) {
            // case 'header':
            //  return<Header key={`${item.type}${index}`} title={<a href="/"> <img src={item.logo} /></a>} sections={item.menu} />
            // case 'headerbar':
            //   return <Header key={`${item.type}${index}`} sections={item.menu} />
            case 'post':
              return <MainFeaturedPost bg="light" textStyle="center grey" device={{ bg: "dark" }} key={`${item.type}${index}`} post={item} />
            // case 'singleimage':
            //   return <SingleImage key={`${item.type}${index}`} post={item} />
            case 'embed':
              return <Embed key={`${item.type}${index}`} post={item} />
            // case 'sub':
            //   return <Grid key={`${item.type}${index}`} container spacing={4}> <FeaturedPost key={item.title} post={item} /> </Grid>
            // case 'footer':
            //   return <Footer key={`${item.type}${index}`} title="Eden Garden" description="Luxury Lodges" />
            case 'image':
              return <Img key={`${item.type}${index}`} post={item} />
            case 'checkout':

              return <Booking key={`${item.type}${index}`} post={item} />
            case 'text':
              return <Text key={`${item.type}${index}`} post={item} />
            // case 'spacer':
            //   return <Spacer key={`${item.type}${index}`} post={item} />
            // case 'ico':
            //   return <Ico key={`${item.type}${index}`} post={item} />
            // case 'video':
            //   return <Footer key={`${item.type}${index}`} title="Eden Garden" description="Luxury Lodges" />
            case 'reviews':
              console.log("a review")
              return <Reviews key={`${item.type}${index}`} post={item} />
            // case 'container':
            //   return <Footer key={`${item.type}${index}`} title="Eden Garden" description="Luxury Lodges" />
            default:
              return ""

          }
        }
      })}
      <Footer title="Eden Garden" description="Luxury Lodges" />
      {/* </Row> */}
    </>

  );
}

export default App;
