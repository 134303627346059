import * as React from 'react';
import PropTypes from 'prop-types';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
// import Box from '@mui/material/Box';
import Container from 'react-bootstrap/Container';

function Embed(props) {
  const { post } = props;

  return (
    
     <Container
      sx={{
        position: 'relative',
        // backgroundColor: 'grey.800',
        color: 'grey.800',
        mb: 1,
        'text-align': 'center',
        boxShadow:'none',
      }}
    > 

      {/* Increase the priority of the hero background image */}
      {/* {<img style={{ display: 'none'}}  src={post.image} alt={post.imageText} />} */}
      {/* <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
  
          // opacity: 0.3,
          borderRadius:2,
          // backgroundColor: 'rgba(0,0,0,.7)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          // backgroundImage: `url(${post.image})`,
        }}
      /> */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113.3963665895963!2d-4.37205062296596!3d51.79549808961562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486eddfe5ca70317%3A0x56c54bed3269a2e1!2sEden%20Gardens%20Luxury%20Lodges!5e1!3m2!1sen!2suk!4v1687445463021!5m2!1sen!2suk" width="350px" height="300px" style={{'border-radius':'5px'}}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </Container>
   
  );
}



export default Embed;
