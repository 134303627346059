import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  List,
  BA,
  BSmall,Typography,Link,
} from 'bootstrap-4-react';

function Copyright() {
  return (
    <>
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://edengardenlodges.com/">
        Eden Lets
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    </>
  );
}

function Footer(props) {
  const { description, title } = props;

  return (
      <Container as="footer" py="5">
        
        <Row>
          <Col col="12 md">
            {/* <Logo /> */}
            <BSmall display="block" mb="3" text="muted">&copy;  Eden Lets  {new Date().getFullYear()}</BSmall>
            <a color="inherit" href="https://edengardenlodges.com/">
        Eden Lets
      </a>
          </Col>
          <Col col="6 md">
            <h5>Features</h5>
            <List unstyled>
              <List.Item><BA text="muted" href="#">Photos</BA></List.Item>
              <List.Item><BA text="muted" href="#">Links</BA></List.Item>
            </List>
          </Col>
          {/* <Col col="6 md">
            <h5>Resources</h5>
            <List unstyled>
              <List.Item><BA text="muted" href="#">Resource</BA></List.Item>
              <List.Item><BA text="muted" href="#">Resource name</BA></List.Item>
              <List.Item><BA text="muted" href="#">Another resource</BA></List.Item>
              <List.Item><BA text="muted" href="#">Final resource</BA></List.Item>
            </List>
          </Col>
          <Col col="6 md">
            <h5>Resources</h5>
            <List unstyled>
              <List.Item><BA text="muted" href="#">Business</BA></List.Item>
              <List.Item><BA text="muted" href="#">Education</BA></List.Item>
              <List.Item><BA text="muted" href="#">Government</BA></List.Item>
              <List.Item><BA text="muted" href="#">Gaming</BA></List.Item>
            </List>
          </Col>
          <Col col="6 md">
            <h5>About</h5>
            <List unstyled>
              <List.Item><BA text="muted" href="#">Team</BA></List.Item>
              <List.Item><BA text="muted" href="#">Locations</BA></List.Item>
              <List.Item><BA text="muted" href="#">Privacy</BA></List.Item>
              <List.Item><BA text="muted" href="#">Terms</BA></List.Item>
            </List>
          </Col> */}
        </Row>
      </Container>
  )
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;
