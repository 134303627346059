import * as React from 'react';
import PropTypes from 'prop-types';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
// import Box from '@mui/material/Box';
// import ImageList from '@mui/material/ImageList'
// import ImageListItem from '@mui/material/ImageListItem'
import {
  Col,
  Card,
  BA,
  BDiv,
  BStrong,
  BH3,
  Container,
  Row,
  BImg
} from 'bootstrap-4-react';

function Img(props) {
  const { post } = props;

  return (
    <Container>
<Row>
  

    {post.images.map((item, index) => (
    <Col   col="col-lg-4 col-md-12 mb-4 mb-lg-0" >  
          <img className="w-100 shadow-1-strong rounded mb-4" key={`${item}${index}`} display="block"  src={item} />
     </Col>     
        ))}     

   
      </Row>
      </Container>
  );

}
// </ImageList>
// <ImageList sx={{ borderRadius:1, width: 360, height: 350 }} cols={1} rowHeight={350}>
{/* <Card key={`${item}${index}`} style={{ width: '18rem' }}>
<Card.Image className="w-100 shadow-1-strong rounded mb-4" src={item} />
</Card> */}
//     <ImageListItem key={`${item}${index}`}>
//       <img

//         src={`${item}?w=164&h=164&fit=crop&auto=format`}
//         srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
//         alt={item}
//         loading="lazy"
//       />
//     </ImageListItem>


export default Img;
