import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Card,
  BA,
  BDiv,
  BStrong,
  BH3,
  Container,
  Row,
  BImg
} from 'bootstrap-4-react';


const style = {
  h: {
    fontFamily: '"Playfair Display", Georgia, "Times New Roman", serif'
  },
  header: {
    lineHeight: '1',
    logo: {
      fontFamily: '"Playfair Display", Georgia, "Times New Roman", serif',
      fontSize: '2.25rem'
    }
  },
  navigator: {
    position: 'relative',
    zIndex: '2',
    height: '2.75rem',
    overflowY: 'hidden'
  },
  jumbovision: {
    header: {
      fontFamily: '"Playfair Display", Georgia, "Times New Roman", serif',
      fontSize: '3rem'
    }
  },
  featured: {
    card: {
       height: '250px',
       
       overflow:'hidden',
       textOverflow:'ellipsis',
      image: {
        borderRadius: '0 3px 3px 0'
      }
    }
  },
  blog: {
    post: {
      marginBottom: '4rem',
      title: {
        fontFamily: '"Playfair Display", Georgia, "Times New Roman", serif',
        fontSize: '2.5rem',
        marginBottom: '0.25rem'
      },
      meta: {
        marginBottom: '1.25rem',
        color: '#999'
      }
    },
    pagination: {
      marginBottom: '4rem',
      button: {
        borderRadius: '2rem'
      }
    }
  },
  footer: {
    padding: '2.5rem 0',
    color: '#999',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    borderTop: '.05rem solid #e5e5e5'
  }
}
const thumbnail = 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22200%22%20height%3D%22250%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20200%20250%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_165d3e49a3c%20text%20%7B%20fill%3A%23eceeef%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A13pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_165d3e49a3c%22%3E%3Crect%20width%3D%22200%22%20height%3D%22250%22%20fill%3D%22%2355595c%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2256.203125%22%20y%3D%22131%22%3EThumbnail%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E'


function Reviews(props) {
  const { post } = props;
  console.log(post)
  return (


    <Container>
    <Row>

      {post.items !== undefined ?
      
        post.items.map((item) => {
   
          return <Col md="6">
            <Card flex="md-row" mb="4" shadow="sm" style={style.featured.card}>

              <Card.Body display="flex" flex="column" alignItems="start">
        <BH3 mb="0" style={style.h}>
          <BA text="primary" href="#">{item.title}</BA>
        </BH3>
        <BDiv text="muted" mb="1">{item.date}</BDiv>
      
        <Card.Text className="" mb="auto">
         {item.review}
        </Card.Text>
        {/* <a href="#">Continue reading</a> */}
        <BStrong display="inline-block" mb="2" text="dark" >{item.name} - {item.location}</BStrong>

              </Card.Body>
              
            </Card>
          </Col>
        })

        :
        <Col md="6">
          <Card flex="md-row" mb="4" shadow="sm" style={style.featured.card}>
            <Card.Body display="flex" flex="column" alignItems="start">
            <BStrong display="inline-block" mb="2" text="primary">{post.name} - {post.location}</BStrong>
        <BH3 mb="0" style={style.h}>
          <BA text="dark" href="#">{post.title}</BA>
        </BH3>
        <BDiv text="muted" mb="1">{post.date}</BDiv>
        <Card.Text mb="auto">
         {post.review}
        </Card.Text>
        {/* <a href="#">Continue reading</a> */}
            </Card.Body>
          </Card>
        </Col>
      }
    </Row>
  </Container>


  )
}


export default Reviews;
