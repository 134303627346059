import * as React from 'react';
import PropTypes from 'prop-types';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
// import Box from '@mui/material/Box';
import {
  Container,
  Row,
  Col,
  Button,
  BDiv,
  BSmall,
  Collapse,
  List,
  Lead,
  ButtonGroup,
  Card,
  BA,
} from 'bootstrap-4-react';

const style = {
  siteHeader: {
    backgroundColor: 'rgba(0, 0, 0, .85)',
    a: {
      color: '#999'
    }
  },
  product: {
    overflow: 'hidden',
    device: {
      position: 'absolute',
      right: '10%',
      bottom: '-30%',
      width: '300px',
      height: '540px',
      backgroundColor: '#333',
      borderRadius: '21px',
      transform: 'rotate(30deg)',
      '::before': {
        position: 'absolute',
        top: '10%',
        right: '10px',
        bottom: '10%',
        left: '10px',
        backgroundColor: 'rgba(255, 255, 255, .1)',
        borderRadius: '5px'
      }
    }
  },
  headline: {
    flex: '1',
    device: {
      width: '80%',
      height: '300px',
      borderRadius: '21px 21px 0 0'
    }
  }
}

style.product.device2 = Object.assign({}, style.product.device, {
  top: '-25%',
  right: 'auto',
  bottom: '0',
  left: '5%',
  backgroundColor: '#e5e5e5'
});

function MainFeaturedPost(props) {
  const { post } = props;

  return (
    // <Paper
    //   sx={{
    //     position: 'relative',
    //     // backgroundColor: 'grey.800',
    //     color: 'grey.800',
    //     mb: 4,
    //     boxShadow:'none',
    //   }}
    // >
    //   {/* Increase the priority of the hero background image */}
    //   {<img style={{ display: 'none'}}  src={post.image} alt={post.imageText} />}
    //   <Box
    //     sx={{
    //       position: 'absolute',
    //       top: 0,
    //       bottom: 0,
    //       right: 0,
    //       left: 0,
    //       opacity: 0.3,
    //       borderRadius:2,
    //       backgroundColor: 'rgba(0,0,0,.7)',
    //       backgroundSize: 'cover',
    //       backgroundRepeat: 'no-repeat',
    //       backgroundPosition: 'center',
    //       backgroundImage: `url(${post.image})`,
    //     }}
    //   />
    //   <Grid container>
    //     <Grid item md={6}>
    //       <Box
    //         sx={{
    //           position: 'relative',
    //           p: { xs: 3, md: 6 },
    //           pr: { md: 0 },
    //         }}
    //       >
    //         <Typography component="h1" variant="h3" color="inherit" gutterBottom>
    //           {post.title}
    //         </Typography>
    //         <Typography variant="h5" color="inherit" paragraph>
    //           {post.description}
    //         </Typography>
    //         <Link variant="subtitle1" href={post.link}>
    //           {post.linkText}
    //         </Link>
    //       </Box>
    //     </Grid>
    //   </Grid>
    // </Paper>

    
// /* <React.Fragment>
// <BDiv display="md-flex" w="100" my="md-3" pl="md-3">
//   <Headline bg="dark"textStyle="center white" device={{ bg: "light" }} />
//   {/* <Headline bg="light" text="title" textStyle="center" device={{ bg: "dark" }} /> */
// </BDiv>
//   </React.Fragment> */

    <Col md="4">
      <BA  href={post.link}>
      <Card mb="4" shadow="sm">
        <Card.Image href={post.link} style={{opacity:post.opacity}} top src={post.image} />
        <Card.Body>
        <BDiv display="flex" justifyContent="between" alignItems="center">
            <BSmall href={post.link} text="muted">{post.title}</BSmall>
          </BDiv>
          <Card.Text>{post.description}</Card.Text>

        </Card.Body>
      </Card>
      </BA>
    </Col>



  // <BDiv bg={props.bg} mr="md-3" pt="3 md-5" px="3 md-5" text={props.textStyle} overflow="hidden" style={style.headline}>
  //   <BDiv my="3" py="3">
  //     <h2>{post.title}</h2>
  //     <Lead>{post.description}</Lead>
  //     <BDiv bg={props.device.bg} text="sdfsdf" shadow="sm" mx="auto" style={style.headline.device}> </BDiv>
  //   </BDiv>
  // </BDiv>

  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainFeaturedPost;
