import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image  from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import { useState } from 'react';

function CollapsibleExample({ data, title,image}) {
  const [expanded, setExpanded] = useState(false); 
  const link = (item) => {
    return <Nav.Link onClick={() => setExpanded(false)} as={Link} to={item.url}>{item.display}</Nav.Link>
    // return <NavDropdown.Item href={item.url}><Link to={item.url}>{item.display}</Link></NavDropdown.Item>

  }

  const dropdown = (item) => {
    return <NavDropdown title={item.display} id="collasible-nav-dropdown">
      {item.items.map((dropdownItem) => {
        return getType(dropdownItem)
      })}
    </NavDropdown>
  }

  const dropdownItem = (item) => {
    return <NavDropdown.Item onClick={() => setExpanded(false)} href={item.url}><Link to={item.url}>{item.display}</Link></NavDropdown.Item>
  }

  const divider = (item) => {
    return <NavDropdown.Divider />
  }

  const navleft = (item) => {
    return <Nav className="me-auto">
      {item.items.map((navItem) => {
        return getType(navItem)
      })}
    </Nav>
  }

  const navright = (item) => {
    return <Nav >
      {item.items.map((navItem) => {
        return getType(navItem)
      })}
    </Nav>
  }


  const getType = (item) => {
    switch (item.type) {
      case "link":
        return link(item)
      case "dropdown":
        return dropdown(item)
      case "divider":
        return divider(item)
      case "dropdown_item":
        return dropdownItem(item)
      case "navleft":
        return navleft(item)
      case "navright":
        return navright(item)
    }
  }
//bg-body-tertiary
  return (
    <Navbar expanded={expanded} style={{    marginBottom: '25px'}} collapseOnSelect={true} expand="lg" className="">
      <Container>
        <Navbar.Brand href="/home">
        <img
              src={image}
              // width="30"
              height="70"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            </Navbar.Brand>

        <Navbar.Toggle onClick={() => setExpanded(true)} aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {data.map((item) => {
            return getType(item)
          })}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;